/* RESPONSIBLE TEAM: team-knowledge-foundations */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable promise/prefer-await-to-then */
/* eslint-disable ember/no-controllers */
/* eslint-disable ember/no-classic-classes */
import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import { readOnly, alias } from '@ember/object/computed';
import ErrorHandling from 'embercom/components/mixins/articles/error-handling';
import EventTracking from 'embercom/components/mixins/articles/event-tracking';

export default Controller.extend(ErrorHandling, EventTracking, {
  notificationsService: service(),
  helpCenterService: service(),
  appService: service(),
  permissionsService: service(),
  intl: service(),
  intercomConfirmService: service(),

  app: readOnly('appService.app'),
  site: readOnly('helpCenterService.site'),
  article: alias('model'),
  themeColor: alias('site.themeColor'),

  actions: {
    deleteArticle() {
      this.permissionsService
        .checkPermission(this.model.requiredDeletePermission)
        .then(async () => {
          let confirmOptions = {
            title: this.intl.t('articles.delete-confirmation'),
            confirmButtonText: this.intl.t('articles.delete-article'),
            cancleButtonText: this.intl.t('articles.go-back'),
            primaryButtonType: 'primary-destructive',
            body: this.intl.t('articles.permanent-will-not-get-article-back'),
          };

          if (await this.intercomConfirmService.confirm(confirmOptions)) {
            let article = this.model;
            let collection = article.get('collection');
            let sectionId = article.get('section.id');
            this.model
              .destroyRecord()
              .then(() => {
                this.notificationsService.notifyConfirmation(
                  this.intl.t('articles.article-deleted'),
                );
                if (collection.get('id') !== 'home') {
                  collection.decrementProperty('count');
                }
                this.trackEducateArticleEvent(article, {
                  action: 'deleted',
                  place: 'article',
                  collection_id: collection.get('id'),
                  section_id: sectionId,
                });
                this.transitionToRoute('apps.app.articles.articles');
              })
              .catch((response) => {
                this.handleServerError(response, this.intl.t('articles.problem-deleting'));
              });
          }
        });
    },
  },
});
