/* RESPONSIBLE TEAM: team-knowledge-foundations */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-controllers */

import Controller from '@ember/controller';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import moment from 'moment-timezone';

const SEARCH_BROWSE_FITLERS_START_DATE = '2021-07-22';

export default class CustomerSupportArticlesFlexibleController extends Controller {
  @service appService;
  @service intercomEventService;
  @service reportingService;
  @service store;
  @service intl;
  @tracked range;

  scopingTagIds = null;
  tag = null;
  teammateId = null;
  teamOrTeammate = null;
  searchBrowseFiltersStartDateMoment = moment(SEARCH_BROWSE_FITLERS_START_DATE).locale('en');

  get app() {
    return this.appService.app;
  }

  get customAttributeIds() {
    return this.conversationAttributeDescriptors.map((descriptor) => descriptor.id);
  }

  get dashboardName() {
    return this.intl.t('articles.reports.title');
  }

  get showSearchBrowseFilterWarning() {
    return (
      this.range.startMoment.isBefore(this.searchBrowseFiltersStartDateMoment) &&
      (this.audience?.length || this.channel?.length || this.source?.length)
    );
  }

  @action
  updateRange(range) {
    this.reportingService.updateRange(range);

    let params = {
      action: 'filtered_by_date',
      object: this.reportName,
      date_filter: this.range.selectedRange,
      start_date: range.startMoment.format('YYYY/MM/DD'),
      end_date: range.endMoment.format('YYYY/MM/DD'),
    };
    let teamOrTeammate = this.teamOrTeammate;
    if (teamOrTeammate) {
      params.models = [teamOrTeammate];
    }
    this.intercomEventService.trackAnalyticsEvent(params);
  }

  @action
  trackPageView() {
    let models = [];
    if (this.teammate) {
      models = [this.teammate];
    } else if (this.teamOrTeammate) {
      models = [...models, this.teamOrTeammate];
    }

    this.intercomEventService.trackAnalyticsEvent({
      action: 'viewed',
      object: this.reportName,
      context: this.reportContext,
      start_date: this.range.startMoment.format('YYYY/MM/DD'),
      end_date: this.range.endMoment.format('YYYY/MM/DD'),
      models,
    });
  }

  @action
  filterBarSelectionChanged(filterType, filterValues) {
    this.set(filterType, filterValues);
  }
}
