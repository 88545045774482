/* RESPONSIBLE TEAM: team-self-serve */

import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { type Router } from '@ember/routing';
import { CheckoutType } from 'embercom/routes/apps/app/checkout/success';

export default class CheckoutInviteTeammatesController extends Controller {
  @service declare router: Router;
  @service declare appService: any;

  checkoutType: CheckoutType | undefined;
  queryParams = [{ checkoutType: 'checkout_type' }];

  get app() {
    return this.appService.app;
  }

  private get nextPageRoute() {
    switch (this.checkoutType) {
      case CheckoutType.ACHPostAnnualMigration:
      case CheckoutType.DirectCheckoutPostAnnualMigration:
        return 'apps.app.billing.summary';
      default:
        return 'apps.app.index';
    }
  }

  @action
  navigateToNextPage() {
    this.router.transitionTo(this.nextPageRoute, this.app.id);
  }
}
