/* RESPONSIBLE TEAM: team-standalone */

import Controller from '@ember/controller';
import { action } from '@ember/object';

export default class FrameDevController extends Controller {
  queryParams = ['external_id', 'external_type'];

  external_id = null;
  external_type = null;

  @action setParams(paramName: string, paramValue: string) {
    this.set(paramName as keyof this, paramValue);
  }
}
