/* RESPONSIBLE TEAM: team-self-serve */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/require-computed-property-dependencies */
/* eslint-disable ember/no-classic-classes */
import Controller, { inject as controller } from '@ember/controller';
import { computed } from '@ember/object';
import { alias, and, not, oneWay, readOnly } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import { equalToProperty } from '@intercom/pulse/lib/computed-properties';
import { task } from 'ember-concurrency';
import ENV from 'embercom/config/environment';
import { MARKETO_FORM_IDS } from 'embercom/lib/gtm';
import { getVisitorId, hideIntercomWidgetWhenLoaded } from 'embercom/lib/intercom-widget-helper';

export default Controller.extend({
  gtmService: service(),
  purchaseAnalyticsService: service(),
  store: service(),
  media: service(),
  iamService: service(),
  session: service(),
  intl: service(),

  teamsController: controller('signup/teams'),
  signupController: controller('signup'),

  appInstall: alias('signupController.model'),
  email: alias('appInstall.email'),
  name: alias('appInstall.name'),
  emailSubmissionEmail: alias('teamsController.email'),
  emailSubmissionId: alias('teamsController.emailSubmissionId'),
  notChangedEmail: equalToProperty('emailSubmissionEmail', 'email'),
  operatorPage: alias('teamsController.operatorPage'),
  selectedLocale: alias('teamsController.selectedLocale'),
  purchaseExperience: readOnly('signupController.purchaseExperience'),
  redirectUrl: null,
  gclid: alias('teamsController.gclid'),
  developer: alias('teamsController.developer'),
  requireVerification: alias('teamsController.requireVerification'),
  talon: alias('appInstall.talon'),
  showGdprConsent: false,

  queryParams: {
    showGdprConsent: 'show_gdpr_consent',
  },

  hideWidgetIfAppropriate() {
    if (this.mobileDevice) {
      hideIntercomWidgetWhenLoaded();
    }
  },

  setAppInstallProperties(properties) {
    let defaultProps = {
      mobile: this.mobileDevice,
      tablet: this.media.isTablet,
      termsOfServiceAccepted: true,
      purchaseExperience: this.purchaseExperience,
      pageViewId: this.gtmService.pageViewId(),
      requireVerification: this.requireVerification,
      userId: getVisitorId(),
    };

    let parsedEmailSubmissionId = Number.parseInt(this.emailSubmissionId, 10);
    if (!Number.isNaN(parsedEmailSubmissionId)) {
      defaultProps.emailSubmissionId = parsedEmailSubmissionId;
    }
    this.appInstall.setProperties(Object.assign(defaultProps, properties));
  },

  authenticatedAdminWithNoApps: and('appInstall.authenticatedAdmin', 'noApp'),

  updateWithFormData(formData) {
    this.setAppInstallProperties({
      name: formData.name,
      email: formData.email,
      password: formData.password,
      appName: formData.appName,
      talon: formData.talon,
      consentProvided: formData.consentProvided,
    });
  },

  redirectUrlParamForSignIn: computed('redirectUrl', function () {
    if (!this.redirectUrl) {
      return;
    }
    return `?redirect_url=${this.redirectUrl}`;
  }),

  formTask: alias('createDeveloperWorkspace'),

  mobileDevice: oneWay('media.isMobile'),
  mobileCart: not('media.isDesktop'),
  signupHeader: computed('intl.locale', 'developer', function () {
    return this.intl.t('signup.teams.form_title.create_developer_account');
  }),
  developerWorkspaceFeatureFlags: ['app-framework-developer-workspace'],

  additionalLogging: computed(function () {
    return window.location.hostname.startsWith(ENV.APP.stagingHostname);
  }),

  createDeveloperWorkspace: task(function* (formData, handleCreationError) {
    this.updateWithFormData(formData);

    let appInstall = this.appInstall;
    let app;

    this.setProperties({
      adminErrors: null,
      appErrors: null,
      networkError: null,
    });

    try {
      this.setAppInstallProperties({
        termsOfServiceAccepted: true,
        newAppFeatureFlags: this.developerWorkspaceFeatureFlags,
        purchaseExperience: 'dev_workspace',
        adminLocale: this.selectedLocale,
      });

      app = yield appInstall.createAppWithAdminAndSignup().then((appResponseData) => {
        this.gtmService.fireMarketoEvent(MARKETO_FORM_IDS.developerSignup, {
          App_ID__c: appResponseData.id,
          Email: appInstall.email || formData.email,
          name: appInstall.name,
          Company: appInstall.appName,
        });

        this.store.pushPayload({
          apps: [appResponseData],
        });
        return this.store.peekRecord('app', appResponseData.id);
      });
    } catch (response) {
      return handleCreationError(response);
    }

    this.iamService.boot(app);
    yield app.reload();
    yield app.get('admins').findBy('id', app.get('currentAdmin.id'));

    this.purchaseAnalyticsService.trackEvent({
      action: 'completed',
      object: 'create_developer_account',
      context: 'usecase_signup_flow',
      place: 'create_account',
      app_id_code: app.id,
      admin_id: app.get('currentAdmin.id'),
      mobile: this.showNewMobileView,
      emailSubmissionId: this.emailSubmissionId,
      via_single_sign_on: this.appInstall.viaSingleSignOn,
    });

    this.transitionToRoute('apps.app.developer-hub', app);
  }),
});
