/* RESPONSIBLE TEAM: team-growth-opportunities */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-classic-classes */
import Controller from '@ember/controller';
import { action, computed } from '@ember/object';
import { oneWay, readOnly, reads, equal } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import { hideIntercomWidgetWhenLoaded } from 'embercom/lib/intercom-widget-helper';

export default Controller.extend({
  queryParams: {
    planId: 'plan_id',
    billingPeriod: 'billing_period',
    seatNumber: 'seat_number',
    extendedTrial: 'extended_trial',
  },
  planId: null,
  extendedTrial: null,
  billingPeriod: undefined,
  seatNumber: undefined,
  solutionId: '',
  checkout: null,
  appService: service(),
  customerService: service(),
  purchaseAnalyticsService: service(),
  media: service(),
  router: service(),
  earlyStageService: service(),
  session: service(),
  intl: service(),
  cardlessTrialService: service(),
  app: readOnly('appService.app'),
  selectedLocale: reads('intl.primaryLocale'),
  mobileDevice: oneWay('media.isMobile'),
  showExpiredTrialCheckout: computed(
    'app.teamPurchaseExperienceTrialExtensionsVariant',
    'extendedTrial',
    function () {
      return this.app.teamPurchaseExperienceTrialExtensionsVariant && this.extendedTrial === 'true';
    },
  ),
  isVerifyPageRoute: equal('router.currentRouteName', 'apps.app.teams-checkout.verify'),
  isEarlyStageApplicationRoute: equal(
    'router.currentRouteName',
    'apps.app.teams-checkout.early-stage-application',
  ),
  notEarlyStageApplicationOrVerifyRoute: computed(
    'isVerifyPageRoute',
    'isEarlyStageApplicationRoute',
    function () {
      // Allow early stage and verify route past new checkout
      return !this.isVerifyPageRoute && !this.isEarlyStageApplicationRoute;
    },
  ),
  hideWidgetIfAppropriate() {
    if (this.mobileDevice) {
      hideIntercomWidgetWhenLoaded();
    }
  },
  changeLocale: action(function (newLocale) {
    this.session.changeLanguage(newLocale, this.app, this.app.currentAdmin);
  }),
});
