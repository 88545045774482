/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import type Header from 'embercom/components/billing/summary/header';
import type CardlessTrialSummary from 'embercom/components/billing/summary/cardless-trial-summary';
import type CardlessTrialService from 'embercom/services/cardless-trial-service';
import { type BillingSummaryRouteModel } from 'embercom/routes/apps/app/settings/subscription/billing/summary';
import type BillingSummaryRoute from 'embercom/routes/apps/app/settings/subscription/billing/summary';

export default class BillingSummary extends Controller {
  @service declare appService: $TSFixMe;
  @service declare cardlessTrialService: CardlessTrialService;

  queryParams = [];
  declare model: ModelFromRoute<BillingSummaryRoute>;

  get contract(): BillingSummaryRouteModel {
    return this.model;
  }

  get inTrialOrExpiredTrial() {
    return this.cardlessTrialService.inTrialOrExpiredTrial;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Billing::Summary::Header': typeof Header;
    'Billing::Summary::CardlessTrialSummary': typeof CardlessTrialSummary;
  }
}
