/* RESPONSIBLE TEAM: team-actions */
import Controller from '@ember/controller';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { debounce } from '@ember/runloop';
import ENV from 'embercom/config/environment';
import { isPresent } from '@ember/utils';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency-decorators';
import { post } from 'embercom/lib/ajax';
import { objectIcons, objectTypes } from 'embercom/models/data/matching-system/matching-constants';
import { capitalize } from '@ember/string';
import type IntlService from 'ember-intl/services/intl';
import { type TaskGenerator } from 'ember-concurrency';
import {
  AVAILABLE_ACTION_COLUMNS,
  DEFAULT_ACTION_COLUMN_NAMES,
} from 'embercom/models/workflow-connector/constants/available-action-columns';
import CreateActionsDropdownOptionComponent from 'embercom/components/workflow-connector/create-actions-dropdown-option-component';
import type Action from 'embercom/models/workflow-connector/action';
import { type Action as HeroBannerAction } from 'embercom/components/common/hero-banner';

const REQUIRED_PERMISSION = 'can_access_developer_hub';

export default class CustomActionsIndex extends Controller {
  queryParams = ['actionId'];

  actionIcons = {
    shopify: 'shopify',
    statuspage: 'statuspage',
    stripe: 'stripe',
  } as $TSFixMe;

  @service declare store;
  @service declare appService: $TSFixMe;
  @service declare intercomEventService: $TSFixMe;
  @service declare router: $TSFixMe;
  @service declare notificationsService: $TSFixMe;
  @service declare intl: IntlService;
  @service declare permissionsService: $TSFixMe;

  @tracked usableTemplateActions = this.loadUsableTemplateActions();
  @tracked searchTerm = '';
  @tracked debouncedSearchTerm = '';
  @tracked selectedActionStateTab = 'any';
  @tracked selectedColumnNames;
  @tracked showTemplatePreviewModal = false;
  @tracked selectedActionTemplate: $TSFixMe;

  bannerIcon = objectIcons[objectTypes.outboundWebhook];
  constructor() {
    super(...arguments);

    let selectedActionsColumns = localStorage.getItem('selected_actions_columns');
    this.selectedColumnNames =
      selectedActionsColumns !== null
        ? JSON.parse(selectedActionsColumns)
        : DEFAULT_ACTION_COLUMN_NAMES;

    this.intercomEventService.trackAnalyticsEvent({
      action: 'viewed',
      object: 'settings',
      tab_viewed: this.selectedActionStateTab,
    });
  }

  get currentTabLabel() {
    return this.intl.t(
      `new-settings.app-settings.custom-actions.tabs.label-${this.selectedActionStateTab}`,
    );
  }

  get app() {
    return this.appService.app;
  }
  get admin() {
    return this.app.currentAdmin;
  }

  get allWorkflowActions() {
    return (this.model as { [key: string]: any }).actions;
  }

  get newHeroBannerActions(): HeroBannerAction[] {
    if (this.app.isStandaloneApp) {
      return [];
    }
    return [
      {
        type: 'link',
        label: 'workflow-connector.new-banner.actions.article-setting-up-actions',
        icon: 'article',
        analyticsId: 'setting-up-actions-article',
        onClick: () => {
          window.Intercom('showArticle', 6298282); // https://www.intercom.com/help/en/articles/6298282-setting-up-a-custom-action
        },
      },
    ];
  }

  loadUsableTemplateActions() {
    if (!this.model) {
      return [];
    }

    return (this.model as { [key: string]: any }).templateActions;
  }

  triggerTour() {
    window.Intercom('startTour', 351602);
  }

  @action
  async onCreateOptionSelected(selectedOption: $TSFixMe) {
    if (!this.validatePermissionAndAllowAction('create-action')) {
      return;
    }

    if (selectedOption === 'create-custom-action') {
      await this.createAction();
    } else {
      this.selectedActionTemplate = this.store.peekRecord(
        'workflow-connector/setup-action',
        selectedOption,
      );
      this.showTemplatePreviewModal = true;
      this.trackAnalyticsEvent(
        'open-template-action-modal',
        'custom-action',
        this.selectedActionTemplate.id,
      );
    }
  }

  @action
  async createAction() {
    let newWorkflowAction = await this.store
      .createRecord('workflow-connector/action', { usage: 'fin' })
      .save();

    this.trackAnalyticsEvent('created', 'custom-action', newWorkflowAction.id);
    this.router.transitionTo(
      'apps.app.settings.app-settings.custom-actions.custom-action',
      newWorkflowAction.id,
    );
  }

  @task
  *createActionFromTemplate(templateActionId: $TSFixMe): TaskGenerator<void> {
    try {
      let params = {
        app_id: this.app.id,
        template_action_id: templateActionId,
      };
      let createdAction = yield post(
        `/ember/workflow_connector/actions/create_action_from_template`,
        params,
      );
      this.trackAnalyticsEvent('created-from-template', 'custom-action', createdAction.id);
      this.router.transitionTo(
        'apps.app.settings.app-settings.custom-actions.custom-action',
        createdAction.id,
      );
    } catch (exception) {
      this.notificationsService.notifyError(
        this.intl.t('workflow-connector.actions.templates.error'),
      );
    }
  }

  @action
  changeActionStateTab(value: string) {
    this.selectedActionStateTab = value;
  }

  @action
  closeTemplatePreviewModal() {
    this.showTemplatePreviewModal = false;
    this.trackAnalyticsEvent(
      'closed-template-action-modal',
      'custom-action',
      this.selectedActionTemplate.id,
    );
  }

  get workflowActionsToDisplay() {
    return isPresent(this.debouncedSearchTerm)
      ? this.searchedWorkflowActions
      : this.filteredWorkflowActions;
  }

  get filteredWorkflowActions() {
    if (this.selectedActionStateTab === 'any') {
      return this.allWorkflowActions;
    }
    return this.allWorkflowActions.filterBy('state', this.selectedActionStateTab);
  }

  get searchedWorkflowActions() {
    let downcasedSearchTerm = this.debouncedSearchTerm.toLocaleLowerCase();
    return this.filteredWorkflowActions.filter(({ name }: { name: string }) => {
      return name.toLocaleLowerCase().includes(downcasedSearchTerm);
    });
  }

  get hasItemsToDisplay() {
    return this.filteredWorkflowActions.length > 0;
  }

  get unusedTemplates() {
    return this.usableTemplateActions.filter((a: Action) => a.children?.length === 0);
  }

  get createActionsDropdownOptions() {
    if (this.unusedTemplates.length > 0) {
      return [
        this.createCustomActionOption(),
        {
          heading: this.intl.t('workflow-connector.actions.templates.create'),
          items: this.unusedTemplates.map((templateAction: $TSFixMe) => {
            return {
              icon: this.templateActionIcon(templateAction),
              iconUrl: templateAction.iconUrl,
              text: this.templateActionName(templateAction),
              value: `${templateAction.id}`,
              component: CreateActionsDropdownOptionComponent,
            };
          }),
        },
      ];
    } else {
      return [this.createCustomActionOption()];
    }
  }

  get hasMultipleActionOptions() {
    return this.createActionsDropdownOptions.length > 1;
  }

  get columnSelectorData(): { text: string; value: string; isSelected?: boolean }[] {
    return AVAILABLE_ACTION_COLUMNS.filter((column) => column.valuePath !== 'name').map(
      (column) => {
        return {
          text: this.intl.t(`workflow-connector.list.columns.${column.valuePath}`),
          value: column.valuePath,
          isSelected: this.selectedColumnNames.includes(column.valuePath),
        };
      },
    );
  }

  get selectedColumns() {
    return AVAILABLE_ACTION_COLUMNS.filter(
      (column) =>
        this.selectedColumnNames.includes(column.valuePath) || column.valuePath === 'name',
    );
  }

  @action
  onSelectionChange(selectedColumnNames: string[]) {
    this.selectedColumnNames = selectedColumnNames;
    localStorage.setItem('selected_actions_columns', JSON.stringify(selectedColumnNames));
  }

  templateActionIcon(action: Action) {
    if (action.appPackageCode && this.actionIcons.hasOwnProperty(action.appPackageCode)) {
      return this.actionIcons[action.appPackageCode];
    }

    return action.isRecommendedAction ? 'webhook' : 'new';
  }

  templateActionName(action: Action) {
    if (action.isRecommendedAction) {
      return capitalize(action.name);
    }
    return `${capitalize(action.appPackageCode)}: ${action.name}`;
  }

  createCustomActionOption() {
    return {
      items: [
        {
          icon: 'new',
          text: this.intl.t('workflow-connector.actions.create-custom'),
          value: 'create-custom-action',
        },
      ],
    };
  }

  updateSearchTerm() {
    this.debouncedSearchTerm = this.searchTerm;
  }

  trackAnalyticsEvent(action: string, object: string, actionId = null) {
    this.intercomEventService.trackAnalyticsEvent({
      action,
      object,
      actionId,
    });
  }

  @action
  onChangeSearchTerm() {
    this.trackAnalyticsEvent('searched', 'custom-action');
    debounce(this, this.updateSearchTerm, ENV.APP._250MS);
  }

  validatePermissionAndAllowAction(object: string) {
    if (!this.permissionsService.currentAdminCan(REQUIRED_PERMISSION)) {
      this.permissionsService.loadAllAdminsAndShowPermissionRequestModal(REQUIRED_PERMISSION);
      this.intercomEventService.trackAnalyticsEvent({
        action: 'blocked',
        object,
      });
      return false;
    }
    return true;
  }
}
