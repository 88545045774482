/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import Controller from '@ember/controller';

export default class BillingSettings extends Controller {
  queryParams = [{ redirectSource: 'redirect_source' }];

  declare model: {
    canCancelSubscription: $TSFixMe;
    emptyBillingAddresseesList: $TSFixMe;
  };

  // Possible values: 'exit_survey' | 'cancellation_flow' | null
  redirectSource: string | null = null;
}
