/* RESPONSIBLE TEAM: team-knowledge-foundations */
import Controller from '@ember/controller';
import { tracked } from '@glimmer/tracking';
import { type EditorMode } from 'embercom/components/knowledge-hub/content-editor/drawer-editor';
import { DRAWER_EDITOR_QUERY_PARAMS } from 'embercom/services/knowledge-hub-drawer-editor-service';

export default class ArticleReviewsController extends Controller {
  queryParams = [...DRAWER_EDITOR_QUERY_PARAMS];

  // Drawer Editor query params
  @tracked activeContentId?: number;
  @tracked activeContentType?: string;
  @tracked editorMode?: EditorMode;
  @tracked collectionId?: string;
  @tracked contentLocale?: string;
  @tracked folderId?: string;
}
