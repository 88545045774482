/* RESPONSIBLE TEAM: team-help-desk-experience */
import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import type Inbox2Counters from 'embercom/services/inbox2-counters';
import type Session from 'embercom/services/session';
import { InboxType } from 'embercom/models/data/inbox/inbox-types';

export default class InboxWorkspaceController extends Controller {
  queryParams = [];

  @service declare inbox2Counters: Inbox2Counters;
  @service declare session: Session;
  @service declare appService: $TSFixMe;

  get inboxCount() {
    return this.inbox2Counters.countForInbox({
      id: this.session.teammate.id.toString(),
      type: InboxType.Admin,
    });
  }

  get canUseInbox() {
    return !!(
      this.session.workspace.isFeatureEnabled('ember-purchase-free') ||
      this.session.workspace.isFeatureEnabled('inbox')
    );
  }

  get canUseOutbound() {
    return this.session.workspace.isFeatureEnabled('auto_messaging');
  }
}
