/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import type IntlService from 'ember-intl/services/intl';

export default class CancelCompleteController extends Controller {
  @service declare appService: $TSFixMe;
  @service declare purchaseAnalyticsService: $TSFixMe;
  @service declare modalService: $TSFixMe;
  @service declare intl: IntlService;

  queryParams = [];

  get app() {
    return this.appService.app;
  }

  get product() {
    if (this.appService.app.onFinForPlatforms) {
      return this.intl.t('new-settings.workspace.billing.product-fin');
    }
    return this.intl.t('new-settings.workspace.billing.product');
  }

  @action redirect() {
    this.transitionToRoute('apps.app');
  }

  @action restart() {
    this.purchaseAnalyticsService.trackEvent({
      action: 'clicked',
      context: 'billing_cancel',
      place: 'cancel_complete',
      object: 'restart_subscription',
    });
    this.transitionToRoute('apps.app.settings.subscription.billing.index');
  }

  @action deleteWorkspace() {
    this.purchaseAnalyticsService.trackEvent({
      action: 'clicked',
      context: 'billing_cancel',
      place: 'cancel_complete',
      object: 'delete_workspace',
    });
    this.modalService.openModal('settings/modals/delete-app-modal', this.app);
  }
}
