/* RESPONSIBLE TEAM: team-purchase */
import Controller from '@ember/controller';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

export default class IntershopAddOnsAddOnController extends Controller {
  queryParams = [
    'showInvalidPlanCombinationModal',
    'showPermissionsModal',
    'showSecondaryWorkspaceModal',
  ];

  @tracked showInvalidPlanCombinationModal = null;
  @tracked showPermissionsModal = null;
  @tracked showSecondaryWorkspaceModal = null;

  get isInvalidPlanModalOpen(): boolean {
    return this.showInvalidPlanCombinationModal !== null;
  }

  get isPermissionModalOpen(): boolean {
    return this.showPermissionsModal !== null;
  }

  get isSecondaryWorkspaceModalOpen(): boolean {
    return this.showSecondaryWorkspaceModal !== null;
  }

  @action
  onModalClose(): void {
    this.showInvalidPlanCombinationModal = null;
    this.showPermissionsModal = null;
    this.showSecondaryWorkspaceModal = null;
  }
}
