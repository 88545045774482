/* RESPONSIBLE TEAM: team-knowledge-foundations */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable ember/no-controllers */
/* eslint-disable ember/use-brace-expansion */
import { computed } from '@ember/object';
import { capitalize } from '@ember/string';
import EventTracking from 'embercom/components/mixins/articles/event-tracking';
import BaseArticleController from 'embercom/controllers/apps/app/articles/articles/article/base';
import { inject as service } from '@ember/service';
import { readOnly, not, reads } from '@ember/object/computed';
import { CAN_MANAGE_ARTICLES_PERMISSION } from 'embercom/lib/articles/constants';

export default BaseArticleController.extend(EventTracking, {
  showOverlay: null,
  showSettingsSideDrawer: false,
  displaying: null,
  helpCenterService: service(),
  intercomEventService: service(),
  permissionsService: service(),
  notificationsService: service(),
  router: service(),
  appService: service(),
  app: reads('appService.app'),
  selectedLocaleName: readOnly('helpCenterService.currentlySelectedLocaleInAll.name'),
  currentlySelectedLocaleId: readOnly('helpCenterService.currentlySelectedLocaleId'),
  selectedContent: computed(
    'article',
    'article.articleContents',
    'currentlySelectedLocaleId',
    function () {
      return this.article.contentByLocale(this.currentlySelectedLocaleId);
    },
  ),
  contentIsMissing: not('article.hasSelectedLocalizedContent'),
  site: readOnly('helpCenterService.site'),
  missingContentText: computed(
    'article.missingListErrorCount',
    'article.missingTableErrorCount',
    'article.missingImageErrorCount',
    'article.missingVideoErrorCount',
    function () {
      let missingContentStrings = ['image', 'table', 'list', 'video']
        .map((missingContentType) => {
          let missingContentText = null;
          let missingContentCount = this.get(
            `article.missing${capitalize(missingContentType)}ErrorCount`,
          );
          let missingContentWord =
            missingContentType === 'list' ? 'nested list' : missingContentType;

          if (missingContentCount > 0) {
            let pluralizedMissingContentWord =
              missingContentCount > 1 ? `${missingContentWord}s` : missingContentWord;
            missingContentText = `${missingContentCount} ${pluralizedMissingContentWord}`;
          }

          return missingContentText;
        })
        .filter((text) => text !== null);

      if (missingContentStrings.length > 2) {
        return `${missingContentStrings.slice(0, -1).join(', ')}, and ${
          missingContentStrings[missingContentStrings.length - 1]
        }`;
      }

      return missingContentStrings.join(' and ');
    },
  ),
  isLive: computed(
    'selectedContent',
    'selectedContent.state',
    'selectedContent.isPublished',
    function () {
      return this.selectedContent !== undefined && this.selectedContent.isPublished;
    },
  ),

  _trackClickedEvent(buttonName) {
    let articleState = this.selectedContent.isPublished ? 'published' : 'unpublished';

    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: buttonName,
      place: 'show',
      section: 'articles',
      article_id: this.article.id,
      content_id: this.selectedContent.id,
      state: articleState,
      locale: this.selectedContent.locale,
    });
  },

  actions: {
    changeView(displaying) {
      let transitionRoute = 'apps.app.articles.articles.article.show';
      if (displaying !== 'article') {
        this.router.transitionTo(`${transitionRoute}.stats`, { queryParams: { displaying } });
      } else {
        this.router.transitionTo(transitionRoute, { queryParams: { displaying: null } });
      }
    },
    async executeShowEditSetting() {
      try {
        await this.permissionsService.checkPermission(CAN_MANAGE_ARTICLES_PERMISSION);
        if (
          this.helpCenterService.site.segmentedContentTurnedOn &&
          this.article.hasRestrictedAudience &&
          !this.article.ruleset.audiencePreview
        ) {
          await this.article.ruleset.fetchAudiencePreview();
        }
      } catch (e) {
        return;
      }
      this.set('showOverlay', 'settings');
    },
    async editArticle() {
      let content = this.article.articleContents.filter(
        (ac) => ac.locale === this.helpCenterService.currentlySelectedLocaleId,
      ).firstObject;
      if (!content) {
        try {
          await this.permissionsService.checkPermission(this.article.requiredEditPermission);
        } catch (e) {
          return;
        }
        content = await this.article.createContentWithVersionIfNone(
          this.helpCenterService.currentlySelectedLocaleId,
        );
      }
      this.transitionToRoute('apps.app.articles.articles.article-content.edit', content);
    },

    async executeUnpublishAndReload() {
      try {
        if (!this.selectedContent) {
          return;
        }
        try {
          await this.permissionsService.checkPermission(CAN_MANAGE_ARTICLES_PERMISSION);
        } catch (e) {
          return;
        }
        await this.selectedContent.unpublish.perform();
        await this.article.reload();

        let message = 'Your article is only visible to your team';
        this.notificationsService.notifyConfirmation(message);
      } catch (error) {
        if (error && error.message !== 'TaskCancelation') {
          this.handleServerError(error, 'There was a problem unpublishing your article');
        }
      }
      this._trackClickedEvent('unpublish_button');
    },

    async executePublishAndReload() {
      try {
        if (!this.selectedContent) {
          return;
        }
        try {
          await this.permissionsService.checkPermission(CAN_MANAGE_ARTICLES_PERMISSION);
        } catch (e) {
          return;
        }
        await this.selectedContent.publish.perform();
        await this.article.reload();
        let message = 'Your article has been published';
        this.notificationsService.notifyConfirmation(message);
      } catch (error) {
        if (error && error.message !== 'TaskCancelation') {
          this.handleServerError(error, 'There was a problem publishing your article');
        }
      }
      this._trackClickedEvent('publish_button');
    },
  },
});
