/* RESPONSIBLE TEAM: team-conversational-knowledge */
import Controller from '@ember/controller';
import { tracked } from '@glimmer/tracking';
import { DRAWER_EDITOR_QUERY_PARAMS } from 'embercom/services/knowledge-hub-drawer-editor-service';
import { type EditorMode } from 'embercom/components/knowledge-hub/content-editor/drawer-editor';
import { action } from '@ember/object';
import { trackedInLocalStorage } from 'ember-tracked-local-storage';
import storage from 'embercom/vendor/intercom/storage';

const AI_INSIGHTS_SUGGESTIONS_SIDE_PANEL_STORAGE_KEY = 'ai_insights_suggestions_side_panel';

export default class InsightsV3MonitorController extends Controller {
  declare model: {
    conversationAttributeDescriptors: any;
  };

  queryParams = [...DRAWER_EDITOR_QUERY_PARAMS];

  // Drawer Editor query params
  @tracked activeContentId?: number;
  @tracked activeContentType?: string;
  @tracked editorMode?: EditorMode;
  @tracked topicsInFilter: string[] = [];

  @trackedInLocalStorage({
    keyName: AI_INSIGHTS_SUGGESTIONS_SIDE_PANEL_STORAGE_KEY,
  })
  showSidePanel = storage.get(AI_INSIGHTS_SUGGESTIONS_SIDE_PANEL_STORAGE_KEY) ?? true;

  @action
  toggleSidePanel() {
    this.showSidePanel = !this.showSidePanel;
  }

  @action
  onSelectedTopicChanged(topic: string | null) {
    if (topic) {
      this.topicsInFilter = [topic];
    } else {
      this.topicsInFilter = [];
    }
  }
}
