/* RESPONSIBLE TEAM: team-proactive-support */

import OutboundBaseListController from 'embercom/lib/outbound/base-list-controller';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { getAutoplayUrlForWistiaId } from '@intercom/pulse/lib/wistia-url';
import {
  objectTypes,
  objectFeatures,
} from 'embercom/models/data/matching-system/matching-constants';
import {
  OUTBOUND_COLUMN_DATA,
  OUTBOUND_COLUMN_KEYS,
  DEFAULT_COLUMN_LIST,
} from 'embercom/lib/outbound/constants';
import { task } from 'ember-concurrency-decorators';
import { tracked } from '@glimmer/tracking';
import { isBlank } from '@ember/utils';

export default class AllOutboundListController extends OutboundBaseListController {
  @service outboundHomeService;
  @service intl;
  @service appService;
  @service intercomEventService;
  @service store;

  @tracked phoneNumbers;
  @tracked onboardingStarted = false;
  @tracked showVideoModal = false;

  ongoingMessagesVideoUrl = getAutoplayUrlForWistiaId('mo33ei9e1o');

  carouselFeature = objectFeatures[objectTypes.carousel];
  pushFeature = objectFeatures[objectTypes.push];
  wistiaId = 'kdm24m3zm5';

  get app() {
    return this.appService.app;
  }

  get selectedTableColumns() {
    return this.outboundHomeService.selectedTableColumns;
  }

  get selectedObjectTypeIds() {
    return this.outboundHomeService.convertToObjectTypeWithLegacy(
      this.outboundHomeService.selectedObjectTypes,
    );
  }

  get isPresetViewChanged() {
    return (
      this.hasAppliedFilter ||
      this.selectedTableColumns.toString() !== DEFAULT_COLUMN_LIST.toString()
    );
  }

  get columnsData() {
    let columns = [
      OUTBOUND_COLUMN_KEYS.checkBox,
      OUTBOUND_COLUMN_KEYS.title,
      ...this.selectedTableColumns,
    ];
    return columns.map((value) => {
      let data = OUTBOUND_COLUMN_DATA[value];
      if (data.labelIntlKey) {
        data.label = this.intl.t(data.labelIntlKey);
      }
      return data;
    });
  }

  get isEmailSelected() {
    return this._selectedObjectTypesIncludeOnly(objectTypes.email, objectTypes.legacyMessageEmail);
  }

  get isMobileSelected() {
    return this.isPushAndCarouselSelected || this.isPushSelected || this.isCarouselSelected;
  }

  get isSmsSelected() {
    return this._selectedObjectTypesIncludeOnly(objectTypes.sms);
  }

  get mobileProductName() {
    if (this.isPushAndCarouselSelected) {
      return this.intl.t('outbound.all.banners.push_or_carousel');
    } else if (this.isPushSelected) {
      return this.intl.t('outbound.all.banners.push');
    }
    return this.intl.t('outbound.all.banners.carousel');
  }

  get mobileFeatureKey() {
    if (this.isPushAndCarouselSelected) {
      //Both push and carousel features are available (no paywall) use carousel for default
      return this.carouselFeature;
    }
    return this.isCarouselSelected ? this.carouselFeature : this.pushFeature;
  }

  get isPushAndCarouselSelected() {
    return (
      this._selectedObjectTypesIncludeOnly(
        objectTypes.push,
        objectTypes.legacyMessagePush,
        objectTypes.carousel,
      ) &&
      this.canUseCarousel &&
      this.canUsePush
    );
  }

  get isPushSelected() {
    return (
      this._selectedObjectTypesIncludeOnly(objectTypes.push, objectTypes.legacyMessagePush) &&
      this.canUsePush
    );
  }

  get isCarouselSelected() {
    return this._selectedObjectTypesIncludeOnly(objectTypes.carousel) && this.canUseCarousel;
  }

  get canUseCarousel() {
    return this.app?.canUseFeature(this.carouselFeature);
  }

  get canUsePush() {
    return this.app?.canUseFeature(this.pushFeature);
  }

  get showOnboardingHome() {
    return isBlank(this.phoneNumbers) || this.onboardingStarted;
  }

  @task
  *fetchAssociatedPhoneNumbers() {
    this.phoneNumbers = yield this.store.findAll('sms/phone-number');
  }

  @action
  setOnboardingStarted(started) {
    this.onboardingStarted = started;
  }

  get disableNewSmsButton() {
    return this.isPaywalled || isBlank(this.phoneNumbers);
  }

  // disables new sms message and setup sms buttons if paywall is visible
  get isPaywalled() {
    return !this.appService.app?.canUseFeature('sms') && !this.appService.app.canUseSmsBeta;
  }

  get showSmsOnboardingPage() {
    return (
      this.isSmsSelected && !this.fetchAssociatedPhoneNumbers.isRunning && this.showOnboardingHome
    );
  }

  get bannerData() {
    return {
      pageTitle: this.intl.t('outbound.all.hero-banner.page-title'),
      button: this.intl.t('outbound.all.hero-banner.button'),
      icon: 'outbound',
    };
  }

  get showFilters() {
    return this.hasAppliedFilter || this.hasItemsToDisplay;
  }

  @action
  updatedSelectedTableColumns(columns) {
    this.outboundHomeService.selectedTableColumns = columns;
    this.intercomEventService.trackAnalyticsEvent({
      action: 'changed_displayed_columns',
      object: 'all_table',
      context: 'outbound_home',
      columns,
    });
  }

  @action openVideoModal() {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'video_opened',
      object: 'all_banner',
      context: 'outbound_home',
      linked_to: this.wistiaId,
    });
    this.showVideoModal = true;
  }

  _selectedObjectTypesIncludeOnly(...objectTypes) {
    let selectedObjectTypes = this.outboundHomeService.convertToObjectTypeWithLegacy(
      this.outboundHomeService.selectedObjectTypes,
    );
    if (selectedObjectTypes?.length !== objectTypes.length) {
      return false;
    }
    for (let objectType of objectTypes) {
      if (!selectedObjectTypes?.includes(objectType)) {
        return false;
      }
    }
    return true;
  }
}
