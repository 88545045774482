/* RESPONSIBLE TEAM: team-help-desk-experience */
import Controller from '@ember/controller';
import { action } from '@ember/object';
import type RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';

export default class SlaController extends Controller {
  @service declare router: RouterService;
  @service declare intercomEventService: $TSFixMe;

  queryParams = [];
  declare model: { officeHoursSchedules: any; slas: any };

  @action
  showSLAHelpArticle() {
    window.Intercom('showArticle', 6546152); // https://www.intercom.com/help/en/articles/6546152-set-slas-for-conversations-and-tickets
  }

  @action
  navigateToWorkflows(fromBanner = false) {
    this.router.transitionTo('apps.app.automation.workflows-overview');
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: 'new_sla_button',
      place: 'settings.sla',
      section: fromBanner ? 'discovery_banner.sla' : 'header.sla',
    });
  }
}
