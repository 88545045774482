/* RESPONSIBLE TEAM: team-standalone */
import Controller from '@ember/controller';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';

export default class IntercomMessengerIntegrateController extends Controller {
  queryParams = ['section'];

  @tracked declare section: string | null;

  @action
  setSection(section: string) {
    this.section = section;
  }
}
