/* RESPONSIBLE TEAM: team-frontend-tech */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable ember/require-computed-property-dependencies */
/* eslint-disable ember/no-controllers */
/* eslint-disable ember/no-classic-classes */
import Controller, { inject as controller } from '@ember/controller';
import { computed } from '@ember/object';
import { inject as service } from '@ember/service';
import { alias, readOnly, match, or, equal, not } from '@ember/object/computed';
import RouteRegexes from 'embercom/lib/route-regexes';
import DetectsIntercomradesDomain from 'embercom/lib/detects-intercomrades-domain';
import IntercomradesProductsOverride from 'embercom/lib/intercomrades-products-override';
import showUnderConstruction from 'embercom/lib/show-under-construction';

export default Controller.extend(DetectsIntercomradesDomain, {
  notificationsService: service(),
  appsController: controller('apps'),
  usersController: controller('apps.app.users'),
  companiesController: controller('apps.app.companies'),
  segmentController: controller('apps/app/users/segments/segment'),
  userSegmentController: alias('segmentController'),
  companySegmentController: controller('apps/app/companies/segments/segment'),
  admin: alias('appsController.model'),
  app: alias('model'),
  showPrimaryNav: true,
  navbarCollapsingService: service(),
  // eslint-disable-next-line @intercom/intercom/no-legacy-modal
  modalService: service(),
  router: service(),
  commonPrimaryNavBarService: service(),
  navbarCollapsed: readOnly('navbarCollapsingService.collapsed'),
  currentPath: alias('router.currentRouteName'),
  isOnTicketsOnboardingRoute: match('currentPath', RouteRegexes.appSettings.ticketsOnboarding),
  isOnPlatformRoute: match('currentPath', RouteRegexes.platformRoutes),
  isOnUsersRoute: match('currentPath', RouteRegexes.usersRoute),
  isOnCompaniesRoute: match('currentPath', RouteRegexes.companiesRoute),
  isOnSeriesRoute: match('currentPath', RouteRegexes.seriesRoute),
  isOnOperatorHome: match('currentPath', RouteRegexes.operatorHome),
  isOnIntegrationsRoute: match('currentPath', RouteRegexes.integrationsRoute),
  isOnExcludedOnboardingRoute: match('currentPath', RouteRegexes.excludedFromOnboardingRoutes),
  isOnAnswersRoute: match('currentPath', RouteRegexes.answersRoute),
  isOnIntershopRoute: match('currentPath', RouteRegexes.intershopRoute),
  isOnPrimaryNavExcludedRoute: match('currentPath', RouteRegexes.excludedFromPrimaryNav),

  isOnRouteWithoutSubmenuAndShouldRoundBorders: or(
    'isOnOperatorHome',
    'isOnIntegrationsRoute',
    'isOnAnswersRoute',
    'isOnIntershopRoute',
  ),
  isOnWizardRoute: match('currentPath', RouteRegexes.wizard),
  showUnderConstructionBanner: computed(
    'router',
    'app.{isTestApp,isDeveloperWorkspace}',
    function () {
      return showUnderConstruction(this.app, this.router);
    },
  ),

  setPageTitle(title) {
    if (this.get('app.isStandaloneApp')) {
      document.title = `${title} | ${this.get('app.name')} | Fin`;
    } else {
      document.title = `${title} | ${this.get('app.name')} | Intercom`;
    }
  },

  modifiedProducts: computed(function () {
    return IntercomradesProductsOverride.isEnabled(this.get('app.id'));
  }),

  hasCompanies: computed('app.companySegments', function () {
    let allSegment = this.get('app.companySegments').findBy('identifier', 'all');
    return allSegment.get('count') > 0;
  }),
  showCompanyLink: readOnly('app.companiesActive'),

  currentRouteIsFeed: equal('currentPath', 'apps.app.feed.index'),

  hasNoActiveSubscription: not('app.hasActiveSubscription'),

  get isOnFloatingWidgetExcludedRoute() {
    return !!(
      this.router.currentRouteName.match(RouteRegexes.gettingStarted) ||
      this.router.currentRouteName.match(RouteRegexes.appStoreOrHome) ||
      this.router.currentRouteName.match(RouteRegexes.adminProfile) ||
      this.router.currentRouteName.match(RouteRegexes.teamsCheckout) ||
      this.router.currentRouteName.match(RouteRegexes.checkout) ||
      this.router.currentRouteName.match(RouteRegexes.expiredSubscription) ||
      this.router.currentRouteName.match(RouteRegexes.developerHub.all)
    );
  },

  get canUsePrimaryNav() {
    return (
      (!this.app.isStandaloneApp || this.app.allowAccessOutsideStandaloneSection) &&
      ((this.showPrimaryNav && !this.isOnPrimaryNavExcludedRoute) ||
        this.router.currentRouteName.match(RouteRegexes.gettingStarted))
    );
  },

  actions: {
    transitionToInbox(inboxIdentifier) {
      if (inboxIdentifier) {
        this.transitionToRoute(this.get('app.inboxConversationsRoute'), inboxIdentifier);
      } else {
        this.transitionToRoute(this.get('app.inboxBaseRoute'));
      }
    },

    openModifyProductsModal(path, params) {
      // eslint-disable-next-line @intercom/intercom/no-legacy-modal
      this.modalService.openModal(path, params);
    },
  },
});
