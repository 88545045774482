/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { CORE_SUPPORT_ID } from 'embercom/lib/billing';
import { PLAN_DATA } from 'embercom/lib/billing';
import type IntlService from 'ember-intl/services/intl';
import type Plan from 'embercom/models/plan';
import type Contract from 'embercom/models/billing/contract';

export default class BillingSummaryIndex extends Controller {
  @service declare appService: $TSFixMe;
  @service declare customerService: $TSFixMe;
  @service declare purchaseAnalyticsService: $TSFixMe;
  @service declare selfServeTrialsService: $TSFixMe;
  @service declare router: $TSFixMe;
  @service declare intl: IntlService;

  queryParams = ['selectedTab', 'oepm', 'esChoiceConfirmation'];

  @tracked showEditPlanModal = false;
  @tracked showRemoveAddonModal = false;
  @tracked selectedTab = 'currentPrice';
  @tracked selectedAddon: $TSFixMe = null;
  @tracked selectedPlan: $TSFixMe = null;
  @tracked oepm = null;
  @tracked esChoiceConfirmation = false;

  get showComparisonPaywall() {
    return (
      this.customerService.onSelfServeVBP1Plan ||
      this.customerService.onSelfServeVBP2Plan ||
      this.app.onPricing5
    );
  }

  get nextAvailableTierId() {
    let selectedPlanData = PLAN_DATA[this.selectedPlan.id];
    if (selectedPlanData.upgradePath) {
      return selectedPlanData.upgradePath[0];
    }
    let plans: Plan[] = this.selectedPlan.product.plans;
    let currentPlanIndex: number = plans.findIndex((plans) => plans.id === this.selectedPlan.id);
    let nextPlan = plans[currentPlanIndex + 1]?.id;
    let previousPlan = plans[currentPlanIndex - 1]?.id;

    return nextPlan || previousPlan;
  }

  get app() {
    return this.appService.app;
  }

  get upgradesToVbp2() {
    if (!this.app.hasValueBasedPricing2) {
      return false;
    }
    return this.app.products.mapBy('id').includes(CORE_SUPPORT_ID);
  }

  get contract() {
    return this.model as Contract;
  }

  get customer() {
    return this.customerService.customer;
  }

  get earlyStageGraduation() {
    return this.customerService.earlyStageGraduation;
  }

  get relativeDailyAppStatUpdatedAt() {
    return this.customerService.currentPrice?.dailyAppStatUpdatedAt
      ? this.intl.formatRelative(this.customerService.currentPrice.dailyAppStatUpdatedAt, {
          style: 'narrow',
        })
      : undefined;
  }

  get canAccessUsagePage() {
    return this.app.onPricing5 && this.app.hasActiveSubscription && !this.customer.inTrial;
  }

  trackModalAction({ object, context }: { object: string; context: string }) {
    this.purchaseAnalyticsService.trackEvent({
      action: 'clicked',
      object,
      context,
      place: 'billing_summary',
    });
  }

  _openEditPlanModal() {
    let plan = [...this.customerService.activePlans, ...this.customerService.activePlanTrials][0];
    if (!plan) {
      return;
    }
    this.openEditPlanModal(plan);
  }

  @action openEditPlanModal(plan: Plan, context = '') {
    this.trackModalAction({ object: 'open_edit_plan_modal_button', context });
    this.selectedPlan = plan;
    this.showEditPlanModal = true;
  }

  @action closeEditPlanModal() {
    this.showEditPlanModal = false;
    this.selectedPlan = null;
    this.oepm = null;
  }

  @action openRemoveAddonModal(plan: Plan, context = '') {
    this.trackModalAction({ object: 'open_edit_addon_modal_button', context });
    this.selectedAddon = plan;
    this.showRemoveAddonModal = true;
  }

  @action closeRemoveAddonModal() {
    this.showRemoveAddonModal = false;
    this.selectedAddon = null;
    this.customerService.resetAll();
  }

  @action setSelectedTab(value: string) {
    if (value === 'graduationPrice') {
      this.purchaseAnalyticsService.trackEvent({
        action: 'clicked',
        object: 'graduation_price_tab',
        place: 'billing_summary',
        // dont want to break existing analytics so leaving it as is
        /* eslint-disable-next-line @intercom/intercom/no-bare-strings */
        context: 'early stage',
      });
    }

    this.selectedTab = value;
  }

  @action redirectToInvoice() {
    this.purchaseAnalyticsService.trackEvent({
      action: 'clicked',
      object: 'non_payment_banner_invoice_link',
      place: 'billing_summary',
    });
    this.router.transitionTo('apps.app.billing.invoices');
  }
}
