/* RESPONSIBLE TEAM: team-standalone */

import Controller from '@ember/controller';
import { action } from '@ember/object';

export default class CopilotFrameController extends Controller {
  queryParams = ['external_id', 'external_type', 'copilot_jwt'];

  external_id = null;
  external_type = null;
  copilot_jwt = null;

  @action updateParams(paramName: string, paramValue: string) {
    this.set(paramName as keyof this, paramValue);
  }
}
