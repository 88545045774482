/* RESPONSIBLE TEAM: team-messenger */
import Controller from '@ember/controller';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { INSTALLATION_PLATFORMS } from 'embercom/components/installation-new/constants';
import type SdkApp from 'embercom/models/sdk-app';

export default class Security extends Controller {
  queryParams = [{ tab: { type: 'string' as const } }, { section: { type: 'string' as const } }];

  @tracked tab: INSTALLATION_PLATFORMS = INSTALLATION_PLATFORMS.WEB;
  @tracked section:
    | 'web-messenger-setup'
    | 'web-idv-setup'
    | 'mobile-messenger-setup'
    | 'mobile-idv-setup'
    | '' = 'web-messenger-setup';
  @tracked iosSdkApp: SdkApp | null = null;
  @tracked androidSdkApp: SdkApp | null = null;
  @tracked iosApiKey = '';
  @tracked androidApiKey = '';

  androidApiSecret = '';
  iosApiSecret = '';
  iosGcmKey = '';
  androidGcmKey = '';

  declare model: {
    ios: any;
    android: any;
    pingDomains: any;
  };

  @action
  setSelectedTab(tab: INSTALLATION_PLATFORMS) {
    this.tab = tab;
  }

  @action
  setSelectedSection(
    section:
      | 'web-messenger-setup'
      | 'web-idv-setup'
      | 'mobile-messenger-setup'
      | 'mobile-idv-setup'
      | '',
  ) {
    this.section = section;
  }

  @action
  deleteSdkApp() {
    this.set('model', null);
  }
}
