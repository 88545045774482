/* RESPONSIBLE TEAM: team-self-serve */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-controllers */
import Controller from '@ember/controller';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import ajax from 'embercom/lib/ajax';
import { MARKETO_FORM_IDS } from 'embercom/lib/gtm';
import { hideIntercomWidgetWhenLoaded } from 'embercom/lib/intercom-widget-helper';
import { captureException } from 'embercom/lib/sentry';
import { percentDiscountForSolution } from 'embercom/lib/billing';

export default class EarlyStageApplicationController extends Controller {
  @tracked solutionId;
  @service purchaseAnalyticsService;
  @service intl;
  @service gtmService;
  @service notificationsService;
  @service earlyStageService;
  @service cardlessTrialService;
  @service customerService;
  @service router;
  @service appService;
  @service media;

  get app() {
    return this.appService.app;
  }

  get blockCustomerFromEarlyStageApplication() {
    let onEarlyStage = this.customerService.customer.currentlyOnEarlyStage;
    if (onEarlyStage) {
      this.purchaseAnalyticsService.trackEvent({
        action: 'blocked',
        context: 'on_early_stage',
        place: 'early_stage_application',
        object: 'blocked_customer_component',
      });
    }
    return onEarlyStage;
  }

  get mobileDevice() {
    return !this.media.isDesktop;
  }

  get solutionDiscount() {
    return percentDiscountForSolution(this.solutionId);
  }

  async transitionToCheckout() {
    await this.app.reload();
    await this.customerService.refreshCustomer();
    this.notificationsService.notifyConfirmation(this.intl.t('teams-checkout.early_stage.success'));

    if (
      this.app.get('hasActiveSubscription') &&
      !this.customerService.customer.hasActiveTrialSubscription
    ) {
      this.router.transitionTo('apps.app.billing.details');
    } else {
      this.router.transitionTo('apps.app.teams-checkout');
    }
  }

  hideWidgetIfAppropriate() {
    if (this.mobileDevice) {
      hideIntercomWidgetWhenLoaded();
    }
  }

  @action
  async submitApplication(formData) {
    formData.solution_id = this.solutionId;
    this.set('isSubmitting', true);
    try {
      await ajax({
        url: '/marketing_onboarding/signups/early_stage',
        type: 'POST',
        data: JSON.stringify(formData),
      });

      await this.gtmService.fireMarketoEvent(MARKETO_FORM_IDS.earlyStage, {
        Email: this.app.email || this.app.currentAdmin.email,
        App_ID__c: this.app.id,
        Company: this.app.name,
        name: this.app.currentAdmin.name || this.app.name,
      });

      this.purchaseAnalyticsService.trackEvent({
        action: 'submitted',
        context: 'usecase_signup_flow',
        place: 'early_stage_application',
        object: 'early_stage_application',
        solutionId: this.solutionId,
        app_id_code: this.app.id,
      });
      await this.transitionToCheckout();
    } catch (err) {
      this.set('isSubmitting', false);
      let textKey =
        err.jqXHR.responseJSON && err.jqXHR.responseJSON.error_code
          ? `teams-checkout.early_stage.${err.jqXHR.responseJSON.error_code}`
          : 'teams-checkout.early_stage.error_submit';
      if (
        (this.cardlessTrialService.canUseEarlyStageDetection || this.app.onPricing5) &&
        err.jqXHR.responseJSON.error_code === 'early_stage_application_rejected'
      ) {
        this.notificationsService.notifyError(this.intl.t(textKey));
        await this.app.reload();
        this.cardlessTrialService.initializeUsingApp(this.app);
        this.router.transitionTo('apps.app.billing.index');
      } else {
        this.notificationsService.notifyError(this.intl.t(textKey));
        this.router.transitionTo(this.app.onboardingHomeRoute);
        captureException(err, {
          fingerprint: ['early-stage-application'],
        });
      }
    }
  }
}
