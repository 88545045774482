/* RESPONSIBLE TEAM: team-ai-agent */
import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import type { EntityType } from 'embercom/models/data/entity-types';
import type KnowledgeHubService from 'embercom/services/knowledge-hub-service';
import type { ViewId } from 'embercom/services/reporting-views';

export default class ContentController extends Controller {
  queryParams = ['section'];
  @tracked showSetupModal = false;
  @tracked openSectionId?: string;
  @tracked setupModalEntryPoint?: ViewId = undefined;
  @tracked selectedEntityType: EntityType | null = null;
  @service declare knowledgeHubService: KnowledgeHubService;
  @service declare appService: $TSFixMe;
  @service navbarCollapsingService: $TSFixMe;
  @action async closeSetupModal() {
    this.showSetupModal = false;
    this.selectedEntityType = null;
    this.setupModalEntryPoint = undefined;
    await this.knowledgeHubService.removeEmptySources();
    this.knowledgeHubService.onSummaryChange();
  }

  @tracked showPreview = true;

  @action
  toggleSidebar() {
    this.navbarCollapsingService.toggleNavbarCollapsed();
    let focusIsOnToggleButton = Boolean(
      document.activeElement?.closest('[data-submenu-collapse-toggle-button]'),
    );
    // we don't want the sidebar button to stay focused after click
    if (focusIsOnToggleButton) {
      (document.activeElement as HTMLElement)?.blur();
    }
  }

  @action
  togglePreview() {
    this.showPreview = !this.showPreview;
  }
}
