/* RESPONSIBLE TEAM: team-knowledge-foundations */
import Controller from '@ember/controller';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
// @ts-ignore
import ajax, { get } from 'embercom/lib/ajax';
import { isPresent } from '@ember/utils';
import { tracked } from '@glimmer/tracking';
import { taskFor } from 'ember-concurrency-ts';
import { dropTask } from 'ember-concurrency-decorators';
import { type TaskGenerator } from 'ember-concurrency';
import type Store from '@ember-data/store';
import type IntlService from 'ember-intl/services/intl';
import type Router from '@ember/routing/router-service';
import type IntercomConfirmService from 'embercom/services/intercom-confirm-service';
import { type UploadHelper } from 'embercom/objects/media/media-helper';
import { type TlsSelectionHelper } from 'embercom/components/articles/site/settings/main';

export default class SettingsController extends Controller {
  @tracked pickerColorHex = this.site.themeColor;
  @service declare store: Store;
  @service declare paywallService: $TSFixMe;
  @service declare helpCenterService: $TSFixMe;
  @service declare notificationsService: $TSFixMe;
  @service declare intercomConfirmService: IntercomConfirmService;
  @service declare intercomEventService: $TSFixMe;
  @service declare realTimeEventService: $TSFixMe;
  @service declare intl: IntlService;
  @service declare router: Router;
  @service declare appService: $TSFixMe;

  queryParams = ['sectionId'];
  @tracked sectionId: string | undefined;

  declare model: {
    customDomain: any;
    isManagedCustomDomainEnabled: boolean;
    uploadHelper: UploadHelper;
    tlsSelectionHelper: TlsSelectionHelper;
  };

  constructor() {
    super(...arguments);
    this.realTimeEventService.on(
      'CustomDomainProcessed',
      this,
      this._handleCustomDomainNotification,
    );
  }

  trackEducateEvent(metadata: $TSFixMe) {
    metadata.owner = 'educate';
    this.intercomEventService.trackEvent('educate-event', metadata);
  }

  get site() {
    return this.helpCenterService.site;
  }

  pickerColor() {
    return `#${this.pickerColorHex}`;
  }

  get defaultLocale() {
    let locale = this.site.supportedLocales.findBy('localeId', this.appService.app.locale);
    return !locale ? 'different' : locale.name;
  }

  willDestroy() {
    this.realTimeEventService.off(
      'CustomDomainProcessed',
      this,
      this._handleCustomDomainNotification,
    );
    super.willDestroy();
  }

  _handleCustomDomainNotification(event: $TSFixMe) {
    if (!this.model) {
      return;
    }
    let { customDomainId, state } = event;
    if (this.model.customDomain && this.model.customDomain.id === customDomainId.toString()) {
      this.model.customDomain = { ...this.model.customDomain, state };
      if (state === 'success') {
        let message = this.intl.t('articles.settings.basics.custom-domain.success');
        this.notificationsService.notifyConfirmation(message);
      } else if (state === 'failed') {
        let message = this.intl.t(
          'articles.settings.basics.custom-domain.errors.domain-validation',
        );
        this.notificationsService.notifyError(message);
      }
    }
  }

  @dropTask
  *sectionCanClose(): TaskGenerator<boolean> {
    if (!this.site.hasDirtyAttributes) {
      return true;
    }
    let options = {
      title: this.intl.t('articles.settings.discard-changes.title'),
      body: this.intl.t('articles.settings.discard-changes.body'),
      confirmButtonText: this.intl.t('articles.settings.discard-changes.confirm-button'),
    };
    let confirmed = yield this.intercomConfirmService.confirm(options);
    if (confirmed) {
      this.site.rollbackAttributes();
      this.intercomEventService.trackAnalyticsEvent({
        action: 'discarded',
        object: 'settings',
      });
      return true;
    } else {
      return false;
    }
  }

  resetCardOptionsSnapshot() {
    this.site.customizationOptions.cardOptionsSnapshots = {};
  }

  _assetRequestFormData(type: $TSFixMe, formMetadata: $TSFixMe = {}) {
    let formData = new FormData();
    formData.append('asset_type', type);
    formData.append('app_id', this.appService.app.id);
    formData.append('help_center_id', this.site.id);
    let siteSettingsName = formMetadata?.siteSettingsName;
    if (type === 'collection_card') {
      let isGlobal: $TSFixMe = siteSettingsName === 'collectionCardGlobal';
      let index: number = siteSettingsName.indexOf('-');
      let collectionId = isGlobal ? '' : siteSettingsName.slice(index + 1);
      formData.append('collection_card_is_global', isGlobal);
      formData.append('collection_id', collectionId);
    }
    Object.entries(formMetadata).forEach(([key, value]: $TSFixMe) => {
      if (value) {
        formData.append(key, value);
      }
    });
    return formData;
  }

  _storeUpdatedSite(mediaType: $TSFixMe, updatedSiteResponse: $TSFixMe) {
    if (mediaType === 'collection_card') {
      return;
    }
    this.store.pushPayload({
      'help-center-sites': [updatedSiteResponse],
    });
  }

  _getSwitchHelpCenterWebsiteModalOptions(newValue: $TSFixMe) {
    let turnOnOptions = {
      title: this.intl.t('articles.settings.switch-v1.turn-on.title'),
      body: this.intl.t('articles.settings.switch-v1.turn-on.body', {
        url: this.site.userFriendlyUrl,
      }),
      confirmButtonText: this.intl.t('articles.settings.switch-v1.turn-on.confirm'),
      primaryButtonType: 'primary-live',
    };
    let turnOffOptions = {
      title: this.intl.t('articles.settings.switch-v1.turn-off.title'),
      body: this.intl.t('articles.settings.switch-v1.turn-off.body', {
        url: this.site.userFriendlyUrl,
      }),
      confirmButtonText: this.intl.t('articles.settings.switch-v1.turn-off.confirm'),
      primaryButtonType: 'primary-destructive',
    };
    return newValue ? turnOnOptions : turnOffOptions;
  }

  _getSupportedLocalesDiff(defaultLanguageChange: $TSFixMe) {
    let added = [] as $TSFixMe;
    let removed = [] as $TSFixMe;
    this.site.supportedLocales.map((locale: $TSFixMe) => {
      if (locale.changedAttributes().isSelected) {
        if (locale.isSelected) {
          added.push(locale.localeId);
        } else {
          removed.push(locale.localeId);
        }
      }
    });
    // Cleanup so we don't double-track changes to the default
    if (
      defaultLanguageChange &&
      removed.includes(defaultLanguageChange[0]) &&
      added.includes(defaultLanguageChange[1])
    ) {
      removed = removed.filter((localeId: $TSFixMe) => localeId !== defaultLanguageChange[0]);
      added = added.filter((localeId: $TSFixMe) => localeId !== defaultLanguageChange[1]);
    }
    return { added, removed };
  }

  _trackSupportedLocalesChangeEvent({ added, removed }: $TSFixMe) {
    // We're not tracking changes to the title, just isSelected
    let addedLength: number = added.length;
    let removedLength: number = removed.length;
    if (addedLength + removedLength > 0) {
      this.trackEducateEvent({
        action: 'saved',
        object: 'language',
        place: 'settings',
        owner: 'educate',
        added,
        removed,
      });
    }
  }

  @action
  async refreshCustomDomain() {
    let { customDomain } = this.model;
    if (customDomain) {
      try {
        let updatedCustomDomain = await taskFor(this._refreshCustomDomain).perform(customDomain.id);
        this.model.customDomain = updatedCustomDomain;
        this.trackEducateEvent({
          action: 'refresh-success',
          object: 'custom-domain',
          place: 'settings',
        });
      } catch (exception) {
        this.notificationsService.notifyError(
          this.intl.t('new-settings.helpcenter.settings.error'),
        );
        this.trackEducateEvent({
          action: 'refresh-failure',
          object: 'custom-domain',
          place: 'settings',
        });
      }
    }
  }

  @dropTask
  *_refreshCustomDomain(customDomainId: $TSFixMe): TaskGenerator<void> {
    return yield ajax({
      type: 'PATCH',
      url: `/ember/custom_domains/${customDomainId}/refresh?app_id=${this.appService.app.id}`,
    });
  }

  get refreshCustomDomainIsRunning() {
    return taskFor(this._refreshCustomDomain).isRunning;
  }

  @dropTask
  *loadVisibleCollections(): TaskGenerator<void> {
    let collections = yield get('/ember/articles_collections/home_collections', {
      app_id: this.site.appId,
      help_center_id: this.site.id,
    });

    if (isPresent(collections)) {
      this.site.visibleCollections = collections;
    }
  }

  @action
  updateHeadline(heading: $TSFixMe) {
    this.site.defaultLocale.headline = heading;
  }

  @action
  setColor(color: $TSFixMe) {
    color = color.substring(1);
    this.site.themeColor = color;
  }

  @action
  sectionDidOpen(sectionName: $TSFixMe) {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'opened',
      object: `${sectionName}-section`,
    });
  }

  @action
  sectionDidClose(sectionName: $TSFixMe) {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'closed',
      object: `${sectionName}-section`,
    });
  }
}
