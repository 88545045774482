/* RESPONSIBLE TEAM: team-standalone */

import Controller from '@ember/controller';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';

export default class IntercomMessengerTestController extends Controller {
  queryParams = ['section'];

  @tracked declare section: string | null;

  @action
  setSection(section: string | null) {
    this.section = section;
  }
}

declare module '@ember/controller' {
  interface Registry {
    'apps/app/standalone/deploy/intercom-messenger/test': IntercomMessengerTestController;
  }
}
